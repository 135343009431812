import React from 'react';
import { Button, Flex, LoadingOverlay, Space, Table, Text } from '@mantine/core';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { CLIENT_BRANDS } from '../../gqls/clientBrand';

export const ClientBrandsPage = () => {
  const navigate = useNavigate();

  const { data, loading } = useQuery(CLIENT_BRANDS, { fetchPolicy: 'network-only' });

  const clientBrands = data?.clientBrands || [];

  const rows = clientBrands.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Link to={`/client-brands/${item.id}`}>{item.id.substring(0, 8)}...</Link>
        </Table.Td>
        <Table.Td>{item.name}</Table.Td>
        <Table.Td>{item.email}</Table.Td>
        <Table.Td>{item.sortNumber}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Flex direction="row" justify="space-between">
        <Text>Дилеры</Text>
        <Button onClick={() => navigate('/client-brands/create')} size="compact-xs" variant="outline">
          Добавить дилера
        </Button>
      </Flex>
      <Space h="12px" />
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>Название</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Сортировка</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
