import { useMutation, useQuery } from '@apollo/client';
import { Button, Group, Loader, Modal, Select, Space, Text } from '@mantine/core';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { notifications } from '@mantine/notifications';

import { ADMIN_UPDATE_CAR, CAR } from '../../gqls/car';
import { NameValue } from '../../components/name-value';
import { getCarStatus } from './index';

export const CarPage = () => {
  const params = useParams();

  const [status, setStatus] = useState(null);
  const [updateVisible, setUpdateVisible] = useState(false);

  const { data, loading } = useQuery(CAR, {
    variables: { where: { id: params?.id } },
    onCompleted: (res) => {
      setStatus(res?.car?.status || null);
    },
  });

  const [adminUpdateCar, { loading: updateLoading }] = useMutation(ADMIN_UPDATE_CAR, {
    onCompleted: () => {
      setUpdateVisible(false);
      notifications.show({ title: 'Успешно', message: 'Данные сохранены', color: 'green' });
    },
    onError: (err) => {
      console.error(err);
      notifications.show({ title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз', color: 'red' });
    },
  });

  const handleUpdateStatus = () => {
    adminUpdateCar({ variables: { where: { id: params?.id }, data: { status } } });
  };

  const car = data?.car;

  return (
    <>
      <Text>Объявлениe</Text>
      <Space h="12px" />
      {loading ? (
        <Loader size="sm" />
      ) : car ? (
        <>
          <Group>
            {car.images.map((item) => (
              <Img key={item} src={`/uploads/${item}`} />
            ))}
          </Group>
          <Space h="16px" />
          <NameValue name="Дата создания" value={dayjs(car.createdAt).format('DD.MM.YYYY HH:mm')} />
          <NameValue name="Дата обновления" value={dayjs(car.updatedAt).format('DD.MM.YYYY HH:mm')} />
          <NameValue name="Город" value={car.city} />
          <NameValue name="Пользователь" value={<Link to={`/users/${car.user?.id}`}>{car.user?.phone}</Link>} />
          <NameValue name="Статус" value={getCarStatus(car.status)} />
          <Space h="16px" />
          <NameValue name="Бренд" value={car.brand} />
          <NameValue name="Модель" value={car.model} />
          <NameValue name="Год" value={car.year} />
          <NameValue name="Цена" value={car.price} />
          <Space h="16px" />
          <NameValue name="Описание" value={car.description} column />
          <Space h="16px" />
          <Group>
            <Button onClick={() => setUpdateVisible(true)} variant="outline">
              Изменить статус
            </Button>
          </Group>
        </>
      ) : null}
      <Modal opened={updateVisible} onClose={() => setUpdateVisible(false)} title="Изменить статус">
        <Select
          label="Статус"
          placeholder="Выберите..."
          data={[
            { label: 'Опубликован', value: 'published' },
            { label: 'Заблокирован', value: 'blocked' },
          ]}
          value={status}
          onChange={(v) => setStatus(v)}
        />
        <Space h="16px" />
        <Group justify="flex-end">
          <Button onClick={() => setUpdateVisible(false)} variant="outline">
            Отмена
          </Button>
          <Button onClick={handleUpdateStatus} loading={updateLoading}>
            Изменить
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const Img = styled.img`
  height: 120px;
  object-fit: contain;
`;
