import { createBrowserRouter } from 'react-router-dom';

import { withLayout } from './components/layout';
import { HomePage } from './pages/home';
import { LoginPage } from './pages/login';
import { ReferencesPage } from './pages/references';
import { PrivacyPolicyPage } from './pages/references/privacy-policy';
import { TermsConditionsPage } from './pages/references/terms-conditions';
import { CarsPage } from './pages/cars';
import { CarPage } from './pages/cars/car';
import { UsersPage } from './pages/users';
import { UserPage } from './pages/users/user';
import { ClientBrandsPage } from './pages/clientBrands';
import { CreateClientBrandsPage } from './pages/clientBrands/create';
import { ClientBrandPage } from './pages/clientBrands/clientBrand';
import { UpdateClientBrandsPage } from './pages/clientBrands/update';
import { ComplainsPage } from './pages/complains';

export const router = createBrowserRouter([
  {
    path: '/',
    element: withLayout(HomePage),
  },
  {
    path: '/references',
    element: withLayout(ReferencesPage),
  },
  {
    path: '/references/privacy-policy',
    element: withLayout(PrivacyPolicyPage),
  },
  {
    path: '/references/terms-conditions',
    element: withLayout(TermsConditionsPage),
  },
  {
    path: '/cars',
    element: withLayout(CarsPage),
  },
  {
    path: '/cars/:id',
    element: withLayout(CarPage),
  },
  {
    path: '/users',
    element: withLayout(UsersPage),
  },
  {
    path: '/users/:id',
    element: withLayout(UserPage),
  },
  {
    path: '/client-brands',
    element: withLayout(ClientBrandsPage),
  },
  {
    path: '/client-brands/create',
    element: withLayout(CreateClientBrandsPage),
  },
  {
    path: '/client-brands/:id',
    element: withLayout(ClientBrandPage),
  },
  {
    path: '/client-brands/:id/update',
    element: withLayout(UpdateClientBrandsPage),
  },
  {
    path: '/complains',
    element: withLayout(ComplainsPage),
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);
