import { useMutation } from '@apollo/client';
import { Button, Flex, Modal, Space, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { notifications } from '@mantine/notifications';

import { CREATE_REFERENCE } from '../../gqls/reference';

export const ModalCreateReference = ({ visible, onClose = () => {}, type, refetch }) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const [createReference, { loading: createLoading }] = useMutation(CREATE_REFERENCE, {
    onCompleted: () => {
      refetch();
      onClose();
      setName('');
      setValue('');
      notifications.show({ title: 'Успешно', message: 'Данные сохранены', color: 'green' });
    },
    onError: (err) => {
      console.error(err);
      notifications.show({ title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз', color: 'red' });
    },
  });

  const handleCreate = () => {
    if (!name || !value) return;
    createReference({ variables: { data: { type, name, value } } });
  };

  return (
    <Modal opened={visible} onClose={onClose} title="Добавить справочник">
      <TextInput label="Тип" value={type} disabled />
      <Space h={16} />
      <TextInput label="Название" value={name} onChange={(e) => setName(e.target.value)} />
      <Space h={16} />
      <TextInput label="Значение" value={value} onChange={(e) => setValue(e.target.value)} />
      <Space h={16} />
      <Flex direction="row" align="center" justify="flex-end">
        <Button size="xs" variant="outline" onClick={() => onClose()}>
          Отмена
        </Button>
        <Space w={12} />
        <Button onClick={handleCreate} size="xs" loading={createLoading}>
          Добавить
        </Button>
      </Flex>
    </Modal>
  );
};
