import React, { useState } from 'react';
import { Button, FileInput, Space, Text, Textarea, TextInput } from '@mantine/core';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { notifications } from '@mantine/notifications';
import { useNavigate, useParams } from 'react-router-dom';

import { ADMIN_CLIENT_BRAND, UPDATE_CLIENT_BRAND } from '../../gqls/clientBrand';
import { isValidUrl } from '../../utils';

export const UpdateClientBrandsPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useQuery(ADMIN_CLIENT_BRAND, {
    variables: { where: { id: params.id } },
    onCompleted: (res) => {
      if (res?.adminClientBrand) {
        document.getElementsByName('name')[0].value = res.adminClientBrand.name;
        document.getElementsByName('description')[0].value = res.adminClientBrand.description;
        document.getElementsByName('website')[0].value = res.adminClientBrand.website;
        document.getElementsByName('sortNumber')[0].value = res.adminClientBrand.sortNumber;
      }
    },
  });

  const [updateClientBrand] = useMutation(UPDATE_CLIENT_BRAND);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const description = e.target.description.value;
    const website = e.target.website.value;
    const sortNumber = e.target.sortNumber.value;
    if (website) {
      if (!isValidUrl(website)) {
        notifications.show({ title: 'Ошибка', message: 'Введите правильную ссылку на сайт', color: 'red' });
        return;
      }
    }
    setLoading(true);
    let image;
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch('/api/admin/upload', {
          method: 'POST',
          body: formData,
          headers: { authorization: accessToken },
        });
        const json = await response.json();
        image = json?.filename;
      } catch (err) {
        console.log(err);
        notifications.show({ color: 'red', title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз' });
        setLoading(false);
        return;
      }
    }
    const data = {
      name,
      description,
      website,
      image,
      sortNumber: sortNumber ? parseInt(sortNumber, 10) : 0,
    };
    updateClientBrand({ variables: { where: { id: params.id }, data } })
      .then(() => {
        navigate(`/client-brands/${params.id}`);
      })
      .catch((err) => {
        console.error(err);
        notifications.show({ color: 'red', title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Text>Изменить дилера</Text>
      <Space h="12px" />
      <Form onSubmit={handleSubmit}>
        <TextInput label="Название" required name="name" />
        <Space h="10px" />
        <Textarea label="Описание" required autosize minRows={4} name="description" />
        <Space h="10px" />
        <TextInput label="Сайт" name="website" />
        <Space h="10px" />
        <FileInput
          label="Изображение"
          accept="image/png,image/jpeg"
          placeholder="Выберите изображение"
          value={file}
          onChange={setFile}
        />
        <Space h="10px" />
        <TextInput
          label="Номер сортировки"
          type="number"
          description="Чем меньше число, тем выше стоит дилер"
          name="sortNumber"
        />
        <Space h="20px" />
        <Button loading={loading} type="submit">
          Изменить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.form`
  max-width: 400px;
`;
