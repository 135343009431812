import { gql } from '@apollo/client';

export const CAR_FRAGMENT = gql`
  fragment CarReturn on Car {
    id
    createdAt
    updatedAt
    brand
    model
    year
    price
    city
    description
    images
    searchType
    status
    crashes {
      id
      part
      painted
      chipOrScratch
      dent
      corrosionOrRust
      comment
    }
    color
    engineCapacity
    engineType
    power
    body
    transmission
    drive
    mileage
    steeringWheel
    equipment
    user {
      id
      phone
      email
      name
    }
  }
`;

export const CARS = gql`
  ${CAR_FRAGMENT}
  query CARS($where: CarsWhereInput, $take: Int, $skip: Int) {
    cars(where: $where, take: $take, skip: $skip) {
      ...CarReturn
    }
    carsCount(where: $where)
  }
`;

export const CAR = gql`
  ${CAR_FRAGMENT}
  query CAR($where: CarWhereInput!) {
    car(where: $where) {
      ...CarReturn
    }
  }
`;

export const ADMIN_UPDATE_CAR = gql`
  ${CAR_FRAGMENT}
  mutation ADMIN_UPDATE_CAR($where: CarWhereInput!, $data: AdminUpdateCar!) {
    adminUpdateCar(where: $where, data: $data) {
      ...CarReturn
    }
  }
`;
