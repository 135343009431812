import { gql } from '@apollo/client';

export const COMPLAINS = gql`
  query COMPLAINS($take: Int, $skip: Int) {
    complains(take: $take, skip: $skip) {
      id
      createdAt
      text
      user {
        id
        name
        phone
      }
      car {
        id
        brand
        model
        year
      }
      status
    }
    complainsCount
  }
`;
