import { useMutation, useQuery } from '@apollo/client';
import { Button, Loader, Space, Text, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';

import { CREATE_REFERENCE, REFERENCES, UPDATE_REFERENCE } from '../../gqls/reference';

export const TermsConditionsPage = () => {
  const [text, setText] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const { data, loading, refetch } = useQuery(REFERENCES, { variables: { where: { showDocs: true } } });

  const [createReference] = useMutation(CREATE_REFERENCE);
  const [updateReference] = useMutation(UPDATE_REFERENCE);

  const references = data?.references || [];

  const privacyPolicyRef = references.find((item) => item.type === 'terms-conditions');

  useEffect(() => {
    if (privacyPolicyRef?.value) setText(privacyPolicyRef.value);
  }, [privacyPolicyRef?.value]);

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const refData = {
        type: 'terms-conditions',
        name: 'Пользовательское соглашение',
        value: text,
      };
      if (privacyPolicyRef?.id) {
        await updateReference({ variables: { where: { id: privacyPolicyRef.id }, data: refData } });
      } else {
        await createReference({ variables: { data: refData } });
      }
      refetch();
      notifications.show({ title: 'Успешно', message: 'Данные сохранены', color: 'green' });
    } catch (err) {
      console.error(err);
      notifications.show({ title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз', color: 'red' });
    }
    setSaveLoading(false);
  };

  return (
    <>
      <Text>Пользовательское соглашение</Text>
      <Space h="12px" />
      {loading ? (
        <Loader size="sm" />
      ) : (
        <>
          <Textarea value={text} onChange={(e) => setText(e.target.value)} autosize />
          <Space h="16px" />
          <Button onClick={handleSave} loading={saveLoading}>
            Сохранить
          </Button>
        </>
      )}
    </>
  );
};
