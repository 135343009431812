import { gql } from '@apollo/client';

import { CAR_FRAGMENT } from './car';

export const CLIENT_BRAND_FRAGMENT = gql`
  ${CAR_FRAGMENT}
  fragment ClientBrandReturn on ClientBrand {
    id
    createdAt
    updatedAt
    email
    name
    description
    image
    website
    cars {
      ...CarReturn
    }
    sortNumber
  }
`;

export const CLIENT_BRANDS = gql`
  ${CLIENT_BRAND_FRAGMENT}
  query CLIENT_BRANDS {
    clientBrands {
      ...ClientBrandReturn
    }
  }
`;

export const CREATE_CLIENT_BRAND = gql`
  ${CLIENT_BRAND_FRAGMENT}
  mutation CREATE_CLIENT_BRAND($data: CreateClientBrandInput!) {
    createClientBrand(data: $data) {
      ...ClientBrandReturn
    }
  }
`;

export const UPDATE_CLIENT_BRAND = gql`
  ${CLIENT_BRAND_FRAGMENT}
  mutation UPDATE_CLIENT_BRAND($where: ClientBrandWhereInput!, $data: UpdateClientBrandInput!) {
    updateClientBrand(where: $where, data: $data) {
      ...ClientBrandReturn
    }
  }
`;

export const ADMIN_CLIENT_BRAND = gql`
  ${CLIENT_BRAND_FRAGMENT}
  query ADMIN_CLIENT_BRAND($where: ClientBrandWhereInput!) {
    adminClientBrand(where: $where) {
      ...ClientBrandReturn
    }
  }
`;
