import { Text } from '@mantine/core';
import { styled } from 'styled-components';

export const NameValue = ({ name, fontSize = 'sm', value, valueColor, content, isLast, column, nameWidth = 200 }) => {
  return (
    <View last={isLast ? 'true' : undefined} column={column} nameWidth={nameWidth}>
      {name ? (
        <Text fz={fontSize} className="name" c="gray">
          {name}
        </Text>
      ) : null}
      {content ? (
        content
      ) : (
        <Text fz={fontSize} className="value" style={{ color: valueColor }}>
          {value ? value : '-'}
        </Text>
      )}
    </View>
  );
};

const View = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  margin-bottom: ${(props) => (props.last ? '0px' : '6px')};

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }

  .name {
    width: ${(props) => props.nameWidth}px;
    margin-right: 16px;

    @media only screen and (max-width: 500px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .value {
    white-space: pre-line;
    ${(props) => (props.column ? 'margin-top: 6px' : '')}
  }
`;
