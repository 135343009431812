import { useQuery } from '@apollo/client';
import { Group, LoadingOverlay, Pagination, Space, Table, Text, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import IMask from 'imask';

import { ADMIN_USERS } from '../../gqls/user';

const TAKE = 50;

const phoneMask = IMask.createMask({
  mask: '+7 (000) 000-00-00',
});

export const UsersPage = () => {
  const [activePage, setActivePage] = useState(1);

  const [phone, setPhone] = useState('');

  const [searchPhone, setSearchPhone] = useState('');

  const { data, loading } = useQuery(ADMIN_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
      where: {
        phone: searchPhone ? searchPhone : undefined,
      },
    },
  });

  useEffect(() => {
    const unmaskedPhone = phone.replace(/ /g, '').replace('(', '').replace(')', '').replace(/-/g, '');
    const timeout = setTimeout(() => {
      setSearchPhone(unmaskedPhone);
    }, 750);
    return () => clearTimeout(timeout);
  }, [phone]);

  const users = data?.adminUsers || [];
  const usersCount = data?.adminUsersCount || 0;

  const rows = users.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Link to={`/users/${item.id}`}>{item.id.substring(0, 8)}...</Link>
        </Table.Td>
        <Table.Td>{item.phone}</Table.Td>
        <Table.Td>{item.name}</Table.Td>
        <Table.Td>{item.bonus}</Table.Td>
        <Table.Td>{item.cars.length}</Table.Td>
        <Table.Td>{dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')}</Table.Td>
      </Table.Tr>
    );
  });

  const pagesCount = Math.ceil(usersCount / TAKE);

  return (
    <>
      <Text>Пользователи</Text>
      <Space h="6px" />
      <Group>
        <TextInput
          size="xs"
          label="Номер телефона"
          placeholder="+7 (___) ___-__-__"
          value={phone}
          onChange={(e) => {
            phoneMask.resolve(e.target.value);
            setPhone(phoneMask.value);
          }}
        />
      </Group>
      <Space h="12px" />
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>Номер телефона</Table.Th>
              <Table.Th>Имя</Table.Th>
              <Table.Th>Бонусы</Table.Th>
              <Table.Th>Кол-во объявлений</Table.Th>
              <Table.Th>Дата регистрации</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination onChange={(v) => setActivePage(v)} value={activePage} size="sm" mt={16} total={pagesCount} />
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
