import { useQuery } from '@apollo/client';
import { Badge, LoadingOverlay, Pagination, Space, Table, Text } from '@mantine/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { CARS } from '../../gqls/car';

const TAKE = 50;

export const CarsPage = () => {
  const [activePage, setActivePage] = useState(1);

  const { data, loading } = useQuery(CARS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
    },
  });

  const cars = data?.cars || [];
  const carsCount = data?.carsCount || 0;

  const pagesCount = Math.ceil(carsCount / TAKE);

  const rows = cars.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Link to={`/cars/${item.id}`}>{item.id.substring(0, 8)}...</Link>
        </Table.Td>
        <Table.Td>{item.brand}</Table.Td>
        <Table.Td>{item.model}</Table.Td>
        <Table.Td>{item.year}</Table.Td>
        <Table.Td>{item.price} руб.</Table.Td>
        <Table.Td>{item.city}</Table.Td>
        <Table.Td>{getCarStatus(item.status)}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Text>Объявления</Text>
      <Space h="12px" />
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>Бренд</Table.Th>
              <Table.Th>Модель</Table.Th>
              <Table.Th>Год</Table.Th>
              <Table.Th>Цена</Table.Th>
              <Table.Th>Город</Table.Th>
              <Table.Th>Статус</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination onChange={(v) => setActivePage(v)} value={activePage} size="sm" mt={16} total={pagesCount} />
    </>
  );
};

export const getCarStatus = (status) => {
  if (status === 'published') return <Badge color="green">Опубликован</Badge>;
  if (status === 'blocked') return <Badge color="red">Заблокирован</Badge>;
  return null;
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
