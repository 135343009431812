import { useQuery } from '@apollo/client';
import { Badge, LoadingOverlay, Pagination, Space, Table, Text } from '@mantine/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { COMPLAINS } from '../../gqls/complain';

const TAKE = 50;

export const ComplainsPage = () => {
  const [activePage, setActivePage] = useState(1);

  const { data, loading } = useQuery(COMPLAINS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
    },
  });

  const complains = data?.complains || [];
  const complainsCount = data?.complainsCount || 0;

  const pagesCount = Math.ceil(complainsCount / TAKE);

  const rows = complains.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>{item.text}</Table.Td>
        <Table.Td>
          <Link to={`/users/${item.user.id}`}>{item.user.name}</Link>
        </Table.Td>
        <Table.Td>
          <Link to={`/cars/${item.car.id}`}>
            {item.car.brand} {item.car.model}, {item.car.year}
          </Link>
        </Table.Td>
        <Table.Td>{dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')}</Table.Td>
        <Table.Td>{getComplainStatus(item.status)}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Text>Жалобы</Text>
      <Space h="12px" />
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Жалоба</Table.Th>
              <Table.Th>Пользователь</Table.Th>
              <Table.Th>Объявление</Table.Th>
              <Table.Th>Дата</Table.Th>
              <Table.Th>Статус</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination onChange={(v) => setActivePage(v)} value={activePage} size="sm" mt={16} total={pagesCount} />
    </>
  );
};

export const getComplainStatus = (status) => {
  if (status === 'new') return <Badge color="red">Новый</Badge>;
  if (status === 'done') return <Badge color="green">Выполнен</Badge>;
  return null;
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
