import { gql } from '@apollo/client';

import { CAR_FRAGMENT } from './car';

export const USER_FRAGMENT = gql`
  ${CAR_FRAGMENT}
  fragment UserReturn on User {
    id
    createdAt
    updatedAt
    name
    phone
    email
    birthday
    sex
    avatar
    cars {
      ...CarReturn
    }
    bonus
  }
`;

export const ADMIN_USERS = gql`
  ${USER_FRAGMENT}
  query ADMIN_USERS($where: AdminUsersWhereInput, $take: Int, $skip: Int) {
    adminUsers(where: $where, take: $take, skip: $skip) {
      ...UserReturn
    }
    adminUsersCount(where: $where)
  }
`;

export const ADMIN_USER = gql`
  ${USER_FRAGMENT}
  query ADMIN_USER($where: UserWhereInput!) {
    adminUser(where: $where) {
      ...UserReturn
    }
  }
`;
