import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Flex, Loader, Space, Table, Text } from '@mantine/core';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { REFERENCES } from '../../gqls/reference';
import { EditReference } from './edit-reference';
import { ModalCreateReference } from './modal-create-reference';

export const ReferencesPage = () => {
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery(REFERENCES);

  const [createQuestionReferenceModalVisible, setCreateQuestionReferenceModalVisible] = useState(false);

  const references = data?.references || [];

  const defaultReferences = references
    .filter((item) => item.type === 'default')
    .map((item) => {
      return (
        <Table.Tr key={item.id}>
          <Table.Td>{item.type}</Table.Td>
          <Table.Td>{item.name}</Table.Td>
          <Table.Td>{item.value}</Table.Td>
          <Table.Td>
            <EditReference reference={item} refetch={refetch} />
          </Table.Td>
        </Table.Tr>
      );
    });

  const questionsReferences = references
    .filter((item) => item.type === 'question')
    .map((item) => {
      return (
        <Table.Tr key={item.id}>
          <Table.Td>{item.type}</Table.Td>
          <Table.Td>{item.name}</Table.Td>
          <Table.Td>{item.value}</Table.Td>
          <Table.Td>
            <EditReference reference={item} refetch={refetch} />
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <>
      <Text>Справочник</Text>
      <Space h="12px" />
      {loading ? (
        <Loader size="sm" />
      ) : (
        <>
          <TableView>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Тип</Table.Th>
                  <Table.Th>Название</Table.Th>
                  <Table.Th>Значение</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{defaultReferences}</Table.Tbody>
            </Table>
          </TableView>
          <Space h="36px" />
          <Flex direction="row" justify="space-between" align="center">
            <Text>Вопрос-Ответ</Text>
            <Button size="compact-xs" variant="outline" onClick={() => setCreateQuestionReferenceModalVisible(true)}>
              Добавить
            </Button>
          </Flex>
          <Space h="12px" />
          <TableView>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Тип</Table.Th>
                  <Table.Th>Название</Table.Th>
                  <Table.Th>Значение</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{questionsReferences}</Table.Tbody>
            </Table>
          </TableView>
          <Space h="36px" />
          <Flex>
            <Button onClick={() => navigate('/references/privacy-policy')} variant="outline">
              Политика конфиденциальности
            </Button>
            <Space w="16px" />
            <Button onClick={() => navigate('/references/terms-conditions')} variant="outline">
              Пользовательское соглашение
            </Button>
          </Flex>
        </>
      )}
      <ModalCreateReference
        visible={createQuestionReferenceModalVisible}
        onClose={() => setCreateQuestionReferenceModalVisible(false)}
        type="question"
        refetch={refetch}
      />
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 800px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
