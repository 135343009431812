import { gql } from '@apollo/client';

export const REFERENCES = gql`
  query REFERENCES($where: ReferencesWhereInput) {
    references(where: $where) {
      id
      name
      value
      type
    }
  }
`;

export const CREATE_REFERENCE = gql`
  mutation CREATE_REFERENCE($data: CreateReferenceInput!) {
    createReference(data: $data) {
      id
      name
      value
      type
    }
  }
`;

export const UPDATE_REFERENCE = gql`
  mutation UPDATE_REFERENCE($where: ReferenceWhereInput!, $data: UpdateReferenceInput!) {
    updateReference(where: $where, data: $data) {
      id
      name
      value
      type
    }
  }
`;

export const DELETE_REFERENCE = gql`
  mutation DELETE_REFERENCE($where: ReferenceWhereInput!) {
    deleteReference(where: $where) {
      id
      name
      value
      type
    }
  }
`;
