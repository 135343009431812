import React, { useState } from 'react';
import { Button, FileInput, Space, Text, Textarea, TextInput } from '@mantine/core';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

import { CREATE_CLIENT_BRAND } from '../../gqls/clientBrand';
import { isValidUrl } from '../../utils';

export const CreateClientBrandsPage = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [createClientBrand] = useMutation(CREATE_CLIENT_BRAND);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const repeatPassword = e.target.repeatPassword.value;
    const name = e.target.name.value;
    const description = e.target.description.value;
    const website = e.target.website.value;
    if (password !== repeatPassword) {
      notifications.show({ title: 'Ошибка', message: 'Пароли должны совпадать', color: 'red' });
      return;
    }
    if (!file) {
      notifications.show({ title: 'Ошибка', message: 'Выберите изображение', color: 'red' });
      return;
    }
    if (website) {
      if (!isValidUrl(website)) {
        notifications.show({ title: 'Ошибка', message: 'Введите правильную ссылку на сайт', color: 'red' });
        return;
      }
    }
    setLoading(true);
    let image;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch('/api/admin/upload', {
        method: 'POST',
        body: formData,
        headers: { authorization: accessToken },
      });
      const json = await response.json();
      image = json?.filename;
    } catch (err) {
      console.log(err);
      notifications.show({ color: 'red', title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз' });
      setLoading(false);
      return;
    }
    if (!image) {
      notifications.show({ color: 'red', title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз' });
      return;
    }
    const data = { email, password, name, description, website, image };
    createClientBrand({ variables: { data } })
      .then(() => {
        navigate('/client-brands');
      })
      .catch((err) => {
        console.error(err);
        notifications.show({ color: 'red', title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Text>Добавить дилера</Text>
      <Space h="12px" />
      <Form onSubmit={handleSubmit}>
        <TextInput label="Электронная почта" type="email" required name="email" />
        <Space h="10px" />
        <TextInput label="Пароль" type="password" required name="password" />
        <Space h="10px" />
        <TextInput label="Повторите пароль" type="password" required name="repeatPassword" />
        <Space h="10px" />
        <TextInput label="Название" required name="name" />
        <Space h="10px" />
        <Textarea label="Описание" required autosize minRows={4} name="description" />
        <Space h="10px" />
        <TextInput label="Сайт" name="website" />
        <Space h="10px" />
        <FileInput
          label="Изображение"
          accept="image/png,image/jpeg"
          required
          placeholder="Выберите изображение"
          value={file}
          onChange={setFile}
        />
        <Space h="20px" />
        <Button loading={loading} type="submit">
          Добавить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.form`
  max-width: 400px;
`;
