import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ActionIcon, Button, Flex, Loader, Modal, Space, TextInput } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import { DELETE_REFERENCE, UPDATE_REFERENCE } from '../../gqls/reference';

export const EditReference = ({ reference, refetch }) => {
  const [editVisible, setEditVisible] = useState(false);
  const [editName, setEditName] = useState(reference.name);
  const [editValue, setEditValue] = useState(reference.value);

  const [updateReference, { loading: updateLoading }] = useMutation(UPDATE_REFERENCE, {
    onCompleted: () => {
      setEditVisible(false);
      notifications.show({ title: 'Успешно', message: 'Данные сохранены', color: 'green' });
    },
    onError: (err) => {
      console.error(err);
      notifications.show({ title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз', color: 'red' });
    },
  });

  const [deleteReference, { loading: deleteLoading }] = useMutation(DELETE_REFERENCE, {
    onCompleted: () => {
      refetch();
      notifications.show({ title: 'Успешно', message: 'Данные сохранены', color: 'green' });
    },
    onError: (err) => {
      console.error(err);
      notifications.show({ title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз', color: 'red' });
    },
  });

  const handleUpdate = () => {
    if (!editName || !editValue) return;
    updateReference({
      variables: {
        where: { id: reference.id },
        data: { name: editName, value: editValue, type: reference.type },
      },
    });
  };

  const handleDelete = () => {
    if (window.confirm('Удалить?')) deleteReference({ variables: { where: { id: reference.id } } });
  };

  return (
    <>
      <Flex direction="row" align="center" justify="flex-end">
        <ActionIcon onClick={() => setEditVisible(true)} size="sm" variant="outline">
          <IconEdit style={{ width: '70%', height: '70%' }} />
        </ActionIcon>
        {reference.type === 'default' ? null : (
          <>
            <Space w={12} />
            <ActionIcon onClick={handleDelete} size="sm" variant="outline" color="red" disabled={deleteLoading}>
              {deleteLoading ? <Loader color="red" size="xs" /> : <IconTrash style={{ width: '70%', height: '70%' }} />}
            </ActionIcon>
          </>
        )}
      </Flex>
      <Modal opened={editVisible} onClose={() => setEditVisible(false)} title="Изменить">
        <TextInput label="Тип" value={reference.type} disabled />
        <Space h={16} />
        <TextInput
          label="Название"
          value={editName}
          onChange={(e) => setEditName(e.target.value)}
          disabled={reference.type === 'default'}
        />
        <Space h={16} />
        <TextInput label="Значение" value={editValue} onChange={(e) => setEditValue(e.target.value)} />
        <Space h={16} />
        <Flex direction="row" align="center" justify="flex-end">
          <Button size="xs" variant="outline" onClick={() => setEditVisible(false)}>
            Отмена
          </Button>
          <Space w={12} />
          <Button onClick={handleUpdate} size="xs" loading={updateLoading}>
            Изменить
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
